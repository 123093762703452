

    <!-- About Me -->
    <div class="mccan page">
        <h3 class="title-small">CLOUD ARCHITECT | TECH LEAD | MENTOR | DEVELOPER</h3>
        <h2 class="title">Muditha</h2>
        <div class="content">
            <div class="grid-container">
                <div class="grid-column">
                    <p>Hi, this is one of my COVID 19 lock down (July 2021 outbreak Bondi, Sydney) projects. There may be broken links, incomplete web pages but I will not be fixing them. See below for a list of technologies and tools I've used to build this SPA.                      
                    </p>
                    <div class="row">
                        <div class="col-md-6">
                            <p><b>Azure Static Websites:</b> Becuase it's free + free SSL. They say</p>
                            <p><b>Angular:</b> Becuase I want to try few other things like PWA</p>
                            <p><b>GitHub Actions:</b> Becuase I have only used it only once.</p>
                            <p><b>Visual Studio Code:</b> Becuase I'm so used to it !!!!</p>
                            <p><b>Favicon.io:</b> Easily create a favicon for free</p>
                        </div>
                        <div class="col-md-6">
                            <p><b>GitHub:</b> Could have tried another service</p>
                            <p><b>Canva:</b> Easiest place to create logos and other graphics</p>
                            <p><b>Google Domains:</b> AUD 19.8 this was a mistake. Now I have to manage both GoDaddy and Google Domains accounts</p>
                            <p><b>Envato Market:</b> AUD 41.XX Website theme. Biggest cost :(</p>
                        </div>
                    </div> 
                    <!-- <a href="#" class="btn ajax">Hire Me</a>  -->
                  </div>
            </div>
        </div>
    </div>
    <div class="divider1"></div>
    <!-- Services -->
    <div class="mccan page">
        <h3 class="title-small">In no particular order</h3>
        <h2 class="title">Hobbies</h2>
        <div class="content">
            <div class="grid-container">
                <div class="grid-column">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="feature feature-left">
                                <div class="mccan-icon"> <span class="et-telescope font-35px"></span> </div>
                                <div class="mccan-text">
                                    <h3>Bush walking</h3>
                                    <p>Cricket and beach in Summer. Bushwalking in Winter. Kosciuszko walk - Thredbo to Mount Kosciuszko is the longest walk (13 km return) I have done with my fam.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="feature feature-left">
                                <div class="mccan-icon"> <span class="et-lightbulb font-35px"></span> </div>
                                <div class="mccan-text">
                                    <h3>Bike riding</h3>
                                    <p>Great alternative when I'm bored of running. I'd occationaly try to visit riding tracks including one downhill track at Thredbo. </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="feature feature-left">
                                <div class="mccan-icon"> <span class="et-camera font-30px"></span></div>
                                <div class="mccan-text">
                                    <h3>Cricket</h3>
                                    <p>This should be at the first of my list. If it's the summer, you will find me at a cricket ground on Saturdays. 
                                      <br />
                                      <a href="https://www.play-cricket.com/player_stats/bowling/106679" target="_blank">Play Cricket UK</a> | 
                                      <a href="https://mycricket.cricket.com.au/common/pages/public/rv/cricket/viewplayer.aspx?clubid=3625&playerid=1135405&save=0&entityid=3625" target="_blank">MyCricket AU</a> | 
                                      <a href="https://mycricket.cricket.com.au/common/pages/public/rv/cricket/viewplayer.aspx?clubid=4955&playerid=1135405&entityid=4955" target="_blank">MyCricket AU</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="feature feature-left">
                                <div class="mccan-icon"> <span class="et-presentation font-35px"></span> </div>
                                <div class="mccan-text">
                                    <h3>Camping</h3>
                                    <p>This is another way I can switch off from everything electronic. I try to do this about twice a year. With my usual group of campers.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="feature feature-left">
                                <div class="mccan-icon"> <span class="et-mobile font-35px"></span></div>
                                <div class="mccan-text">
                                    <h3>Other sports</h3>
                                    <p>Badmington, Tennis, Swimming.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="feature feature-left">
                                <div class="mccan-icon"> <span class="et-lightbulb font-35px"></span> </div>
                                <div class="mccan-text">
                                    <h3>Cooking</h3>
                                    <p>This is rare. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
<router-outlet></router-outlet>